import React from "react";
import axios from "axios";
import { navigate } from "gatsby";
import { withAuthentication } from "../../../hoc/withAuthentication";
import * as styles from "./index.module.css";
import Main from "../../../components/main";
import Button from "../../../components/button";
import Input from "../../../components/input";
import TextArea from "../../../components/textArea";
import DeleteTag from "../../../components/deleteTag";
import MissingDataPanel from "../../../components/missingDataPanel";
import ContentArea from "../../../components/contentArea";
import Consts from "../../../config/consts";
import Util from "../../../config/util";
import Api from "../../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED],
        redirectPage: "/",
    },
    class CollectionCreate extends React.Component {
        constructor(props) {
            super(props);
            const params = new URLSearchParams(props.location.search);
            this.state = {
                saving: false,

                hasUpdatedName: false,
                hasUpdatedDescription: false,
                hasUpdatedDisplayImage: false,

                name: "",
                description: "",

                uploadedDisplayImageKey: null,
                dataDisplayImage: null,
                base64DisplayImage: null,

                mediaId: params.get("mediaId"),
                content: null,
            };
        }

        componentDidMount() {
            // check if we were given a media Id to create this collection with
            if (this.state.mediaId) {
                Api.market({
                    endpoint: "/content/media",
                    method: "GET",
                    data: {
                        mediaId: this.state.mediaId,
                    },
                })
                    .then((res) => {
                        this.setState({
                            content: res.data,
                        });
                    })
                    .catch((e) => {
                        Util.notify.error(`Failed to find media ${this.state.mediaId}`);
                        this.setState({
                            content: null,
                        });
                    });
            }
        }

        onSelectDisplayImageFile = () => {
            this.selectDisplayImageFile()
                .then((file) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                        console.log(reader);
                        this.setState({
                            hasUpdatedDisplayImage: true,
                            uploadedDisplayImageKey: null,
                            dataDisplayImage: file,
                            base64DisplayImage: reader.result,
                        });
                    };
                })
                .catch((e) => {
                    Util.notify.error("Invalid file: " + e);
                });
        };
        selectDisplayImageFile = () => {
            return new Promise((resolve, reject) => {
                let input = document.createElement("input");
                input.type = "file";
                input.multiple = false;
                input.accept = "image/*";
                input.onchange = (_) => {
                    let files = Array.from(input.files);
                    if (files[0].size > 50000000) {
                        reject("File size too big.");
                    } else {
                        resolve(files[0]);
                    }
                };
                input.click();
            });
        };

        onNameUpdated = (text) => {
            this.setState({
                name: text,
                hasUpdatedName: true,
            });
        };
        onDescriptionUpdated = (text) => {
            this.setState({
                description: text,
                hasUpdatedDescription: true,
            });
        };

        onRemoveContent = () => {
            this.setState({
                content: null,
            });
        };

        uploadDisplayImage = () => {
            return new Promise((resolve, reject) => {
                if (!this.state.dataDisplayImage) {
                    reject();
                } else if (this.state.uploadedDisplayImageKey) {
                    resolve(this.state.uploadedDisplayImageKey);
                } else {
                    Api.market({
                        endpoint: "/content/upload/url/",
                        method: "GET",
                        data: {
                            contentType: this.state.dataDisplayImage.type.toLowerCase(),
                            dataType: "COLLECTION_DISPLAY_IMAGE",
                        },
                    })
                        .then((upload_res) => {
                            axios({
                                method: "PUT",
                                url: upload_res.url,
                                headers: {
                                    "Content-Type": this.state.dataDisplayImage.type.toLowerCase(),
                                },
                                data: this.state.dataDisplayImage,
                            })
                                .then(() => {
                                    this.setState(
                                        {
                                            uploadedDisplayImageKey: upload_res.key,
                                        },
                                        () => {
                                            this.checkUploadStatus(upload_res.key, 20)
                                                .then(() => {
                                                    resolve(upload_res.key);
                                                })
                                                .catch((e) => {
                                                    reject();
                                                });
                                        }
                                    );
                                })
                                .catch(reject);
                        })
                        .catch(reject);
                }
            });
        };

        checkUploadStatus = (key, maxRetries, retryCount = 0) => {
            return new Promise((resolve, reject) => {
                Api.market({
                    endpoint: "/content/upload/status/",
                    method: "GET",
                    data: {
                        key,
                    },
                })
                    .then((status_res) => {
                        if (status_res.status !== "UPLOADED") {
                            if (maxRetries > retryCount) {
                                setTimeout(() => {
                                    this.checkUploadStatus(key, maxRetries, retryCount + 1)
                                        .then(resolve)
                                        .catch(reject);
                                }, 1000);
                            } else {
                                resolve(false);
                            }
                        } else {
                            resolve(true);
                        }
                    })
                    .catch(reject);
            });
        };

        onCreate = () => {
            if (!this.state.name || this.state.name.length <= 1) {
                Util.notify.error(`Invalid Collection Name provided. Please check and try again.`);
            } else if (!this.state.description || this.state.description.length <= 1) {
                Util.notify.error(`Invalid Collection Description provided. Please check and try again.`);
            } else if (!this.state.base64DisplayImage || this.state.base64DisplayImage.length <= 1) {
                Util.notify.error(`Invalid Collection Image provided. Please check and try again.`);
            } else {
                this.setState(
                    {
                        saving: true,
                    },
                    () => {
                        this.uploadDisplayImage()
                            .then((display_image_key) => {
                                Api.market({
                                    endpoint: "/content/collection",
                                    method: "POST",
                                    data: {
                                        name: this.state.name,
                                        description: this.state.description,
                                        displayImageKey: display_image_key,
                                    },
                                })
                                    .then((res) => {
                                        let newCollectionId = res.collectionId;
                                        if (this.state.content) {
                                            Api.market({
                                                endpoint: "/content/collection/content/media",
                                                method: "POST",
                                                data: {
                                                    collectionId: newCollectionId,
                                                    mediaId: this.state.content.mediaId,
                                                },
                                            })
                                                .then((res) => {
                                                    navigate(`/collection/${newCollectionId}`);
                                                })
                                                .catch((e) => {
                                                    navigate(`/collection/${newCollectionId}`);
                                                });
                                        } else {
                                            navigate(`/collection/${newCollectionId}`);
                                        }
                                    })
                                    .catch((e) => {
                                        Util.notify.error(`Failed to create collection, please retry now or try again later.`);
                                        console.error(e);
                                        this.setState({
                                            saving: false,
                                        });
                                    });
                            })
                            .catch((e) => {
                                Util.notify.error(`Failed to upload the collection image. Please retry or try again later.`);
                                this.setState({
                                    saving: false,
                                });
                            });
                    }
                );
            }
        };

        render() {
            return (
                <Main title={"Create"} auth={this.props.auth} prices={this.props.prices} providers={this.props.providers} currentChain={this.props.currentChain} chains={this.props.chains}>
                    <ContentArea slim={true} extraTopPadding={true} bottomRule={false}>
                        <TextArea center={true}>
                            <p className={"caps_and_spaced"}>Create a Collection</p>
                        </TextArea>
                    </ContentArea>
                    <ContentArea slim={true} extraTopPadding={false} bottomRule={false}>
                        <TextArea header={"Display Image."}>
                            <p>This image will be used to publicly identify this collection.</p>
                            <div className={styles.profile_image_container}>
                                <div className={styles.profile_image_left}>
                                    <div className={styles.profile_image}>{!!this.state.base64DisplayImage && <img src={this.state.base64DisplayImage} />}</div>
                                </div>
                                <div className={styles.profile_image_right}>
                                    <TextArea>
                                        <p>This image should be 500x500 in resolution and can be of filetype: .png, .gif or .jpg</p>
                                        <br />
                                        <Button displayMode={1} hoverMode={6} text={"Select Image"} style={{ margin: 0 }} onClick={this.onSelectDisplayImageFile} />
                                    </TextArea>
                                </div>
                            </div>
                        </TextArea>
                    </ContentArea>

                    <ContentArea slim={true} bottomRule={true}>
                        <TextArea header={"Name."}>
                            <p>Give your Collection a name that is unique amongst your own Collections. This name will be publicly visible.</p>
                            <Input placeholder={"Name your collection"} onTextChanged={this.onNameUpdated} defaultValue={this.state.name} maxLength={50} />
                        </TextArea>
                        <TextArea header={"Description."}>
                            <p>Write out a paragraph or two describing what your collection is of. This description is publicly visible.</p>
                            <Input multiline={true} placeholder={"Describe your collection"} onTextChanged={this.onDescriptionUpdated} defaultValue={this.state.description} maxLength={400} />
                        </TextArea>
                    </ContentArea>

                    <ContentArea slim={true} bottomRule={true}>
                        <TextArea header={"Content."}>
                            {!!this.state.content ? (
                                <p>
                                    To add content to your collection tap the <b>Add To Collection</b> link on the media page, alternatively you can select the collection at the point you add new
                                    media. Use the list below to remove content from your collection.
                                </p>
                            ) : (
                                <p>
                                    To add content to your collection tap the <b>Add To Collection</b> link on the media page, alternatively you can select the collection at the point you add new
                                    media.
                                </p>
                            )}
                            {!!this.state.content && (
                                <div className={styles.content_container}>
                                    <DeleteTag
                                        deleting={false}
                                        onDelete={this.onRemoveContent}
                                        image={`${process.env.GATSBY_STORAGE}media/${this.state.content.displayImage}`}
                                        text={this.state.content.name}
                                    />
                                </div>
                            )}
                        </TextArea>
                    </ContentArea>

                    <ContentArea slim={true} extraTopPadding={true} bottomRule={false}>
                        <TextArea center={true}>
                            <Button
                                displayMode={5}
                                hoverMode={6}
                                disabled={this.state.saving || (!this.state.hasUpdatedName && !this.state.hasUpdatedDescription)}
                                text={this.state.saving ? "Creating..." : "Create Collection"}
                                onClick={this.onCreate}
                            />
                        </TextArea>
                    </ContentArea>
                </Main>
            );
        }
    }
);
